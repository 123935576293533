import api from "./api"

export default class SpellChecker {
  async correct(str) {
    const spell = await api.post('/spell-checker/correct', { text: str })
      .then(res => res.data)
      .catch(err => console.error(err))

    return spell.spell
  }

  async suggest(str) {
    const spell = await api.post('/spell-checker/suggest', { text: str })
      .then(res => res.data)
      .catch(err => console.error(err))

    return spell.spell
  }
}

// export default class SpellChecker {
//   constructor() {
//     this.init()
//   }

//   async init() {
//     const affFile = await fetch('/index.aff')
//       .then((res) => res.text())

//     const dicFile = await fetch('/index.dic')
//       .then((res) => res.text())

//     // this.dictionary = new Typo('pt_BR', affFile, dicFile)
//     // console.log(this.dictionary)
//   }

//   correct(str) {
//     return this.dictionary.check(str)
//   }

//   suggest(str) {
//     return this.dictionary.suggest(str)
//   }
// }
